import notisphereServices from '../../../services/notisphere/email'
export default {
    data() {
        return {
            item: [],
            content: null,
            contentType: 'Login',
            isDisabled: false
        }
    },
    created(){
        this.cancel()
    },
    methods: {
        save() {
            let formData = new FormData()
            formData.append('content', this.content ? this.content.trim() : '')
            formData.append('contentType', this.contentType ? this.contentType : '')
            notisphereServices.contentManagement(formData).then(resp => {
                if (!resp.error) {
                    this.$emit('onCompleted', 'UPDATE')
                    this._showToast('Updated successfully!')
                    this.cancel()
                }
            })
        },
        cancel(){
            notisphereServices.getcontentManagement(this.contentType).then((resp) => {
                if (!resp.error) {
                    this.content = resp.data.d.content
                    this.content = this.content.replaceAll('img src','img style="max-height: 185px;" src')
                }
            })
        }
    }
}